import { FocusContext, setFocus, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import { useEffect } from 'react';
import RedButton from '../../components/Buttons/RedButton';
import { useNavigate } from 'react-router';
import createTranslateFunction from '../../i18n/createTranslateFunction';
import { useDispatch } from 'react-redux';
import { setAppBackground, setAppBackgroundTwClass } from '../../redux/slices/layoutSlice';
import { useSelector } from '../../redux/store';
import PizzahutIcon from '../../assets/pizzahut-icon.svg';

type WelcomeScreenProps = {
    focusKey: string;
};
const WelcomeScreen = ({ focusKey: focusKeyParam }: WelcomeScreenProps) => {
    const TranslateFunction = createTranslateFunction('welcome');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { ref, focusKey } = useFocusable({
        focusable: true,
        focusKey: focusKeyParam,
    });

    const userAuth = useSelector((state) => state.userAuth);

    useEffect(() => {
        const handleBackButton = (event) => {
            event.preventDefault();
            event.stopPropagation();

            const lastConfirmTime = window.localStorage.getItem('lastConfirmTime');
            const now = Date.now();

            if (!lastConfirmTime || now - parseInt(lastConfirmTime) > 2000) {
                const confirmExit = window.confirm('Do you want to leave the app?');
                window.localStorage.setItem('lastConfirmTime', now.toString());

                if (confirmExit) {
                    if (window.webOS) {
                        window.webOS.platformBack();
                    } else {
                        window.close();
                    }
                }
            }
        };

        window.addEventListener('popstate', handleBackButton);

        return () => {
            window.removeEventListener('popstate', handleBackButton);
        };
    }, []);

    useEffect(() => {
        setFocus(focusKeyParam);
        dispatch(
            setAppBackground([
                'https://pizzahut-assets.s3.amazonaws.com/screen-bg/menu/box2.jpg',
                'bg-no-repeat bg-cover bg-opacity-95',
            ]),
        );
        if (userAuth.user?.auth && userAuth.user.has_completed_onboarding) {
            navigate('/');
        } else {
            //If returned to the welcome page, clear local storage so he couldn't press escape to log back.
            localStorage.clear();
        }
    }, []);
    return (
        <FocusContext.Provider value={focusKey}>
            <div className="w-full h-full relative" ref={ref}>
                <div className="z-[1] absolute top-[5vw] flex flex-col items-start h-[90%] w-[50vw] mr-[13vw]">
                    <img
                        className="z-[1] h-[8vw] mb-[3vw]"
                        //src="https://pizzahut-assets.s3.us-east-1.amazonaws.com/icons/assets/pizzahut_logo.svg"
                        src={PizzahutIcon}
                        alt="Pizzahut Logo"
                    />
                    <span
                        className={`z-[1] text-[2.6vw] mb-[1vw] text-white`}
                    >{`${TranslateFunction(`title`)}`}</span>
                    <span className="z-[1] text-[1.5vw] text-white max-w-[62%] mb-[3vw]">{`${TranslateFunction(`description`)}`}</span>
                    <RedButton
                        className="z-[1] !text-[1.5vw] w-[24vw] h-[5vw] !p-0"
                        focusKey="lets-start-button"
                        onClick={() => navigate('/login')}
                    >
                        {`${TranslateFunction(`lets_start_button`)}`}
                    </RedButton>
                </div>
            </div>
        </FocusContext.Provider>
    );
};

export default WelcomeScreen;
