import { FocusContext, setFocus, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import PersonalAssistantLogo from '../../PersonalAssistantLogo';
import { AppDispatch, useSelector } from '../../../../redux/store';
import { useEffect, useState } from 'react';
import { Extra, Item } from '../../../../redux/slices/dbFoodMenuSlice';
import StepBackButton from '../StepBackButton';
import { useDispatch } from 'react-redux';
import {
    ChangeAssistantStep,
    ResetChosenExtras,
    UpdateChooseExtrasQuery,
    UpdateChooseToKeepItem,
} from '../../../../redux/slices/personalAssistantSlice';
import SpeechToTextButton from '../../../../components/SpeechToText/SpeechToTextButton';
import useSpeechRecognition from '../../../../components/SpeechToText/useSpeechRecognition';
import RedButton from '../../../../components/Buttons/RedButton';
import {
    convertToDisplayableItem,
    doesNameIncludePizza,
    removeEnglishLettersFromString,
} from '../../PersonalAssistantHelperFunctions';
import useMelingoQuery, { MelingoExtra } from '../useMelingoQuery';
import { ExtraModel } from '../../../../redux/slices/ordersSlice';
import { PizzaExtraCard } from './PizzaExtraCard';
import { ExtraCard } from './ExtraCard';
import createTranslateFunction from '../../../../i18n/createTranslateFunction';
import TransparentButton from '../../../../components/Buttons/TransparentButton';

type ChooseExtrasProps = {
    focusKey: string;
    chosenItem: Item;
    onRowFocus: () => void;
    setChooseItemKey: React.Dispatch<React.SetStateAction<number>>;
    setChooseExtrasKey: React.Dispatch<React.SetStateAction<number>>;
    handleErrorMessageFromMelingo: (errorMssage?: string) => void;
};
const ChooseExtras = ({
    focusKey: focusKeyParam,
    chosenItem,
    onRowFocus,
    setChooseItemKey,
    setChooseExtrasKey,
    handleErrorMessageFromMelingo,
}: ChooseExtrasProps) => {
    const {
        isListening,
        startListening,
        stopListening,
        voiceRecognitionText,
        setVoiceRecognitionText,
    } = useSpeechRecognition();
    const { melingoData, melingoError, fetchMelingoData } = useMelingoQuery();
    const chooseExtrasTranslation = createTranslateFunction(
        'personal_assistant_screen.choose_extras',
    );
    const dispatch = useDispatch<AppDispatch>();
    const { chosenExtras, chooseToKeepItem, isMelingoQueryLoading, chosenExtrasQuery } =
        useSelector((state) => state.personalAssistant);

    const [currentFocusIndex, setCurrentFocusIndex] = useState<number>(0);
    const { ref, focusKey, focusSelf } = useFocusable({
        focusable: true,
        focusKey: focusKeyParam,
        isFocusBoundary: true,
        focusBoundaryDirections: ['up'],
        preferredChildFocusKey: `STT-CHOOSE-EXTRAS`,
    });

    const handleStepBackFunction = () => {
        dispatch(ResetChosenExtras());
        setChooseItemKey((prev) => prev + 1);
        setFocus('STT-CHOOSE-ITEM');
    };

    const returnExtrasString = (extrasToBeConverted: Extra[]) => {
        let extrasString = '';
        for (const extra of extrasToBeConverted) {
            extrasString += removeEnglishLettersFromString(extra.name) + ', ';
        }
        return extrasString;
    };

    const isSelfMadeSalad = () => {
        return chosenItem.name.includes('בהרכבה');
    };

    const handleFinishedPickingExtras = () => {
        if (isSelfMadeSalad() && chosenExtras.length < 3) {
            handleErrorMessageFromMelingo(`יש להוסיף יותר מ3 תוספות לסלט בהרכבה`);
            return;
        }
        console.log('finished!');
        const itemToDisplay = convertToDisplayableItem(chosenItem, chosenExtras);
        dispatch(UpdateChooseToKeepItem(itemToDisplay));
        dispatch(UpdateChooseExtrasQuery(returnExtrasString(itemToDisplay.extras)));
        setFocus('STT-CHOOSE-SUGGESTED-ITEM');
    };

    const getExtrasFromMelingoResponse = (melingoExtras: MelingoExtra[]) => {
        const TransformedExtras: ExtraModel[] = [];
        for (const melingoExtra of melingoExtras) {
            const transformedExtra = chosenItem.extras.find((chosenItemExtra) =>
                chosenItemExtra.name.includes(removeEnglishLettersFromString(melingoExtra.name)),
            );
            if (!transformedExtra) {
                continue;
            }
            const transformedExtraWithSize = {
                ...transformedExtra,
                extraSize: melingoExtra.extraSize,
            };
            TransformedExtras.push(transformedExtraWithSize as ExtraModel);
        }
        console.log(TransformedExtras, 'these are the transformed extras');
        return TransformedExtras;
    };

    const handleReceivedItemAndExtraDataFromMelingo = () => {
        if (
            !melingoData ||
            !melingoData.data ||
            !melingoData.data.reply ||
            !melingoData.data.reply.items[0] ||
            !melingoData.data.reply.items[0].extras ||
            melingoError
        ) {
            console.log('No data received from Melingo');
            handleErrorMessageFromMelingo(
                `לא מצאנו תוספות מתאימות ל "${chosenExtrasQuery}", בואו ננסה שוב`,
            );
            dispatch(UpdateChooseExtrasQuery(''));
            setChooseExtrasKey((prev) => prev + 1);
            return;
        }
        const receivedMelingoExtras = melingoData.data.reply.items[0].extras
            ? melingoData.data.reply.items[0].extras
            : [];
        if (receivedMelingoExtras.length === 0) {
            handleErrorMessageFromMelingo(
                `לא מצאנו תוספות מתאימות ל "${chosenExtrasQuery}", בואו ננסה שוב`,
            );
            dispatch(UpdateChooseExtrasQuery(''));
            setChooseExtrasKey((prev) => prev + 1);
            return;
            //Throw error
        }
        const chosenExtrasFromMelingo = getExtrasFromMelingoResponse(receivedMelingoExtras);
        const itemToDisplay = convertToDisplayableItem(chosenItem, chosenExtrasFromMelingo);
        dispatch(UpdateChooseToKeepItem(itemToDisplay));
        dispatch(ChangeAssistantStep('chooseToKeepItem'));
        //Else - THROW ERROR, RESET THE SCREEN AND LET THE USER TRY AGAIN
    };
    const shouldDisplayPizzaExtras = () => {
        return chosenItem.extras.length > 0 && doesNameIncludePizza(chosenItem.category.name);
    };

    const getChosenExtrasUserInputText = () => {
        //Add a line that checks if there is a melingo query, if so, display it.
        if (chosenExtrasQuery) {
            return chosenExtrasQuery;
        }
        if (chosenExtras.length < 0) {
            return ``;
        }
        return `אני רוצה ${returnExtrasString(chosenExtras)}`;
    };

    const getChooseExtrasHeader = () => {
        return shouldDisplayPizzaExtras()
            ? chooseExtrasTranslation('pizza_header')
            : chooseExtrasTranslation('salad_header');
    };

    const handleNoExtrasButton = () => {
        const itemToDisplay = convertToDisplayableItem(chosenItem, []);
        dispatch(UpdateChooseToKeepItem(itemToDisplay));
        dispatch(UpdateChooseExtrasQuery(chooseExtrasTranslation('no_extras_button')));
    };

    useEffect(() => {
        onRowFocus();
        focusSelf();
    }, []);

    // Debounce timeout
    const [debounceTimeout, setDebounceTimeout] = useState<NodeJS.Timeout | null>(null);

    useEffect(() => {
        if (voiceRecognitionText.length > 0) {
            // Clear any existing timeout
            if (debounceTimeout) {
                clearTimeout(debounceTimeout);
            }

            // Set a new timeout to process the voice input after a delay
            const timeout = setTimeout(() => {
                console.log('Processing voice input');
                dispatch(UpdateChooseExtrasQuery(voiceRecognitionText));
                console.log('Sending to Melingo ', voiceRecognitionText);
                fetchMelingoData(`${chosenItem.name} ${voiceRecognitionText}`);
                setVoiceRecognitionText(''); // Reset the text after processing
            }, 1000); // Adjust the delay as needed (1000 ms = 1 second)

            setDebounceTimeout(timeout);
        }

        // Cleanup function to clear the timeout if the component unmounts
        return () => {
            if (debounceTimeout) {
                clearTimeout(debounceTimeout);
            }
        };
    }, [voiceRecognitionText]);

    // useEffect(() => {
    //     if (voiceRecognitionText.length > 0) {
    //         dispatch(UpdateChooseExtrasQuery(voiceRecognitionText));
    //         fetchMelingoData(`${chosenItem.name} ${voiceRecognitionText}`);
    //     }
    // }, [voiceRecognitionText]);

    useEffect(() => {
        if (melingoData && melingoData.data) {
            setVoiceRecognitionText('');
            handleReceivedItemAndExtraDataFromMelingo();
        }
    }, [melingoData]);
    console.log(chosenItem, 'this is the chosen item');

    return (
        <FocusContext.Provider value={focusKey}>
            <div className="flex flex-col animate-slowFadeIn items-start justify-center my-[2vw]">
                <div className="flex relative mb-[1vw]">
                    <span className="text-[1.8vw]">{getChooseExtrasHeader()}</span>
                    <PersonalAssistantLogo />
                </div>
                <div className="flex items-center mb-[2vw]">
                    <StepBackButton
                        focusKey="SBB-CHOOSE-EXTRA"
                        handleOnClick={handleStepBackFunction}
                        className="ml-[1.5vw]"
                    />
                    <SpeechToTextButton
                        focusKey="STT-CHOOSE-EXTRAS"
                        isListening={isListening}
                        startClickFunction={startListening}
                        stopClickFunction={stopListening}
                    />
                </div>
                <div
                    ref={ref}
                    // className="flex flex-wrap overflow-visible justify-evenly items-center relative" - THIS IS THE ORIGINAL
                    className={`grid ${shouldDisplayPizzaExtras() ? `grid-cols-4 gap-[1vw]` : `pr-[0.5vw] grid-cols-5 gap-[1vw]`} overflow-visible justify-center items-center relative`}
                >
                    {shouldDisplayPizzaExtras() && (
                        <TransparentButton
                            className="h-[6vw] w-[20vw] flex justify-start items-center pr-[2vw] !text-[1.5vw]"
                            focusClassName="!border-white !border-[0.1vw]"
                            onClick={handleNoExtrasButton}
                        >
                            {chooseExtrasTranslation('no_extras_button')}
                        </TransparentButton>
                    )}
                    {shouldDisplayPizzaExtras()
                        ? chosenItem.extras.map((extra, index) => {
                              return (
                                  <PizzaExtraCard
                                      extraData={extra}
                                      handleErrorMessageFromMelingo={handleErrorMessageFromMelingo}
                                      setCurrentFocusIndex={setCurrentFocusIndex}
                                      componentIndex={index}
                                      key={`pizza-extra-card-${index}`}
                                  />
                              );
                          })
                        : chosenItem.extras.map((extra, index) => {
                              return (
                                  <ExtraCard
                                      extraData={extra}
                                      handleErrorMessageFromMelingo={handleErrorMessageFromMelingo}
                                      setCurrentFocusIndex={setCurrentFocusIndex}
                                      componentIndex={index}
                                      key={`extra-card-${index}`}
                                  />
                              );
                          })}
                    <RedButton
                        className="w-[20vw] !h-[5vw] !p-0 mt-[0.5vw] !text-[1.5vw]"
                        onFocusClass="!border-white !border-[0.1vw]"
                        onClick={handleFinishedPickingExtras}
                    >{`${chooseExtrasTranslation('finished_choosing_button')}`}</RedButton>
                </div>
            </div>
        </FocusContext.Provider>
    );
};

export default ChooseExtras;
