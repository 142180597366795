export const isDevelopment = process.env.NODE_ENV === 'development';
export const isProduction = process.env.NODE_ENV === 'production';

console.log(process.env.NODE_ENV);
console.log('MOBILE_PATH  ', process.env.REACT_APP_MOBILE_PATH);
export const env = {
    BACKEND_URL: process.env.REACT_APP_BACKEND_URL,
    QR_CODE_DATA: process.env.REACT_APP_QR_CODE_DATA,
    MOBILE_PATH: process.env.REACT_APP_MOBILE_PATH,
};
