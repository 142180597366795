import { FocusContext, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import PersonalAssistantLogo from '../../PersonalAssistantLogo';
import { AppDispatch, useSelector } from '../../../../redux/store';
import { SubCategoryCard } from './SubCategoryCard';
import { useEffect, useState } from 'react';
import StepBackButton from '../StepBackButton';
import { useDispatch } from 'react-redux';
import {
    ResetChosenCategory,
    UpdateChooseSubCategoryQuery,
    UpdateChosenSubCategory,
} from '../../../../redux/slices/personalAssistantSlice';
import useSpeechRecognition from '../../../../components/SpeechToText/useSpeechRecognition';
import SpeechToTextButton from '../../../../components/SpeechToText/SpeechToTextButton';
import useMelingoQuery from '../useMelingoQuery';
import { findWhatIsTheCategory } from '../../PersonalAssistantHelperFunctions';
import createTranslateFunction from '../../../../i18n/createTranslateFunction';

type ChooseSubCategoryProps = {
    focusKey: string;
    onRowFocus: () => void;
    setChooseCategoryKey: React.Dispatch<React.SetStateAction<number>>;
    setChooseSubCategoryKey: React.Dispatch<React.SetStateAction<number>>;
    handleErrorMessageFromMelingo: (errorMssage?: string) => void;
    isDisabled: boolean;
};
const ChooseSubCategory = ({
    focusKey: focusKeyParam,
    onRowFocus,
    setChooseCategoryKey,
    setChooseSubCategoryKey,
    handleErrorMessageFromMelingo,
    isDisabled,
}: ChooseSubCategoryProps) => {
    const {
        isListening,
        startListening,
        stopListening,
        voiceRecognitionText,
        setVoiceRecognitionText,
    } = useSpeechRecognition();
    const { isMelingoLoading, melingoData, melingoError, fetchMelingoData } = useMelingoQuery();
    const [chosenSubCategoryUserInput, setChosenSubCategoryUserInput] = useState<string>('');
    const ChosenCategory = useSelector((state) => state.personalAssistant.chosenCategory);
    const { assistantStep, chosenSubCategoryQuery } = useSelector(
        (state) => state.personalAssistant,
    );
    const menuCategories = useSelector((state) => state.dbFoodMenu.categories);
    const isCurrentStep = assistantStep === 'chooseSubCategory' ? true : false;
    const subCategoryTranslation = createTranslateFunction(
        'personal_assistant_screen.choose_sub_category',
    );
    const dispatch = useDispatch<AppDispatch>();
    const [currentFocusIndex, setCurrentFocusIndex] = useState<number>(0);
    const { ref, focusKey, focusSelf } = useFocusable({
        focusable: true,
        onFocus: onRowFocus,
        focusKey: focusKeyParam,
        isFocusBoundary: true,
        focusBoundaryDirections: ['up'],
        preferredChildFocusKey: `STT-CHOOSE-SUBCATEGORY`,
    });

    const handleStepBackButton = () => {
        setChooseCategoryKey((prev) => prev + 1);
        dispatch(ResetChosenCategory());
    };

    const handleReceivedSubCategoryDataFromMelingo = () => {
        if (
            !melingoData ||
            !melingoData.data ||
            !melingoData.data.reply ||
            !melingoData.data.reply.items ||
            melingoError
        ) {
            console.log('No data received from Melingo');
            handleErrorMessageFromMelingo(`קרתה בעיה, בואו ננסה שוב`);
            setChooseSubCategoryKey((prev) => prev + 1);
            dispatch(UpdateChooseSubCategoryQuery(''));
            return;
        }
        const melingoAnswerType = melingoData.data.reply.items[0].type;
        const subCategories = menuCategories[5].sub_categories;
        const whatIsTheCategory = findWhatIsTheCategory(subCategories, melingoAnswerType);

        if (whatIsTheCategory) {
            dispatch(UpdateChosenSubCategory(whatIsTheCategory));
        } else {
            handleErrorMessageFromMelingo(
                `לא מצאנו סוג הקטגוריה  "${chosenSubCategoryQuery}", בואו ננסה שוב`,
            );
            setChooseSubCategoryKey((prev) => prev + 1);
        }
    };
    // Debounce timeout
    const [debounceTimeout, setDebounceTimeout] = useState<NodeJS.Timeout | null>(null);

    useEffect(() => {
        if (voiceRecognitionText.length > 0) {
            // Clear any existing timeout
            if (debounceTimeout) {
                clearTimeout(debounceTimeout);
            }

            // Set a new timeout to process the voice input after a delay
            const timeout = setTimeout(() => {
                console.log('Processing voice input');
                dispatch(UpdateChooseSubCategoryQuery(voiceRecognitionText));
                console.log('Sending to Melingo ', voiceRecognitionText);
                fetchMelingoData(voiceRecognitionText);
                setVoiceRecognitionText(''); // Reset the text after processing
            }, 1000); // Adjust the delay as needed (1000 ms = 1 second)

            setDebounceTimeout(timeout);
        }

        // Cleanup function to clear the timeout if the component unmounts
        return () => {
            if (debounceTimeout) {
                clearTimeout(debounceTimeout);
            }
        };
    }, [voiceRecognitionText]);

    // useEffect(() => {
    //     if (voiceRecognitionText.length > 0) {
    //         dispatch(UpdateChooseSubCategoryQuery(voiceRecognitionText));
    //         fetchMelingoData(voiceRecognitionText);
    //     }
    //     //Reset the current value, so it wont call melingo again.
    // }, [voiceRecognitionText]);

    useEffect(() => {
        if (melingoData && melingoData.data) {
            setVoiceRecognitionText('');
            handleReceivedSubCategoryDataFromMelingo();
        }
    }, [melingoData]);

    useEffect(() => {
        onRowFocus();
        focusSelf();
    }, []);

    return (
        <FocusContext.Provider value={focusKey}>
            <div
                className={`flex flex-col items-start justify-center animate-slowFadeIn w-full pr-[4vw] my-[2vw] ${isDisabled && `pointer-events-none`}`}
            >
                <div className="flex relative">
                    <span className="text-[1.8vw]">
                        {subCategoryTranslation('choose_drink_size')}
                    </span>
                    <PersonalAssistantLogo />
                </div>
                <div
                    ref={ref}
                    className="flex items-center w-[70vw] h-[12vw] px-[0.5vw] overflow-x-auto"
                >
                    <div className="flex items-center ml-[2vw]">
                        {isCurrentStep && (
                            <StepBackButton
                                focusKey="SBB-CHOOSE-SUBCATEGORY"
                                handleOnClick={handleStepBackButton}
                                className="ml-[1.5vw]"
                            />
                        )}
                        <SpeechToTextButton
                            focusKey="STT-CHOOSE-SUBCATEGORY"
                            isListening={isListening}
                            startClickFunction={startListening}
                            stopClickFunction={stopListening}
                        />
                    </div>
                    {ChosenCategory &&
                        ChosenCategory.sub_categories.map((category, index) => {
                            return (
                                <SubCategoryCard
                                    subCategoryData={category}
                                    setCurrentFocusIndex={setCurrentFocusIndex}
                                    componentIndex={index}
                                    key={`sub-category-card-${index}`}
                                />
                            );
                        })}
                </div>
            </div>
        </FocusContext.Provider>
    );
};

export default ChooseSubCategory;
