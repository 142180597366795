import { FocusContext, setFocus, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import PersonalAssistantLogo from '../../PersonalAssistantLogo';
import { AppDispatch, useSelector } from '../../../../redux/store';
import { useEffect, useState } from 'react';
import { Item } from '../../../../redux/slices/dbFoodMenuSlice';
import { ItemCard } from './ItemCard';
import StepBackButton from '../StepBackButton';
import { useDispatch } from 'react-redux';
import {
    ChangeAssistantStep,
    ResetChosenCategory,
    ResetChosenItem,
    ResetChosenSubCategory,
    UpdateChooseItemQuery,
    UpdateChosenItem,
} from '../../../../redux/slices/personalAssistantSlice';
import useSpeechRecognition from '../../../../components/SpeechToText/useSpeechRecognition';
import SpeechToTextButton from '../../../../components/SpeechToText/SpeechToTextButton';
import useMelingoQuery from '../useMelingoQuery';
import 'react-toastify/dist/ReactToastify.css';
import createTranslateFunction from '../../../../i18n/createTranslateFunction';

type ChooseItemProps = {
    focusKey: string;
    chosenCategoryItems: Item[];
    onRowFocus: () => void;
    setChooseCategoryKey: React.Dispatch<React.SetStateAction<number>>;
    setChooseSubCategoryKey: React.Dispatch<React.SetStateAction<number>>;
    setChooseItemKey: React.Dispatch<React.SetStateAction<number>>;
    handleErrorMessageFromMelingo: (errorMssage?: string) => void;
    isDisabled: boolean;
};
const ChooseItem = ({
    focusKey: focusKeyParam,
    chosenCategoryItems,
    onRowFocus,
    setChooseCategoryKey,
    setChooseSubCategoryKey,
    setChooseItemKey,
    handleErrorMessageFromMelingo,
    isDisabled,
}: ChooseItemProps) => {
    const dispatch = useDispatch<AppDispatch>();
    const {
        isListening,
        startListening,
        stopListening,
        voiceRecognitionText,
        setVoiceRecognitionText,
    } = useSpeechRecognition();
    const { melingoData, melingoError, fetchMelingoData } = useMelingoQuery();
    const currentAssistantStep = useSelector((state) => state.personalAssistant.assistantStep);
    const chooseItemTranslation = createTranslateFunction('personal_assistant_screen.choose_item');

    const { chosenSubCategory, chosenCategory, chosenItemQuery } = useSelector(
        (state) => state.personalAssistant,
    );
    const isCurrentStep = currentAssistantStep === 'chooseItem' ? true : false;
    const allMenuItems = useSelector((state) => state.dbFoodMenu.all_items);
    const [currentFocusIndex, setCurrentFocusIndex] = useState<number>(0);
    const { ref, focusKey, focusSelf } = useFocusable({
        focusable: true,
        focusKey: focusKeyParam,
        isFocusBoundary: true,
        focusBoundaryDirections: ['up'],
        preferredChildFocusKey: `STT-CHOOSE-ITEM`,
    });

    const handleFocusAndStepWhenStepBack = () => {
        if (chosenCategory && chosenCategory.name.includes('פיצות')) {
            dispatch(ResetChosenSubCategory());
            dispatch(ChangeAssistantStep('choosePizzaSubCategory'));
            setChooseSubCategoryKey((prev) => prev + 1);
            setFocus('STT-CHOOSE-PIZZA-SUBCATEGORY');
        } else if (chosenSubCategory) {
            dispatch(ResetChosenSubCategory());
            dispatch(ChangeAssistantStep('chooseSubCategory'));
            setChooseSubCategoryKey((prev) => prev + 1);
            setFocus('STT-CHOOSE-SUBCATEGORY');
        } else {
            dispatch(ChangeAssistantStep('chooseCategory'));
            dispatch(ResetChosenSubCategory());
            dispatch(ResetChosenCategory());
            setChooseCategoryKey((prev) => prev + 1);
            setFocus('STT-CHOOSE-CATEGORY-BUTTON');
        }
    };

    const handleStepBackFunction = () => {
        handleFocusAndStepWhenStepBack();
        dispatch(ResetChosenItem());
    };

    const handleReceivedItemDataFromMelingo = () => {
        if (
            !melingoData ||
            !melingoData.data ||
            !melingoData.data.reply ||
            !melingoData.data.reply.items ||
            !melingoData.data.reply.items[0]?.makat ||
            melingoError
        ) {
            console.log('No data received from Melingo');
            handleErrorMessageFromMelingo(`קרתה בעיה, בואו ננסה שוב`);
            dispatch(UpdateChooseItemQuery(''));
            setChooseItemKey((prev) => prev + 1);
            return;
        }
        const receivedMelingoItem = melingoData.data.reply.items[0];
        const foundItemFromMenu = chosenCategoryItems.find(
            (item) => item.makat === receivedMelingoItem?.makat,
        );
        if (foundItemFromMenu) {
            dispatch(UpdateChosenItem(foundItemFromMenu));
        } else {
            handleErrorMessageFromMelingo(`לא מצאנו את המוצר "${chosenItemQuery}", בואו ננסה שוב`);

            dispatch(UpdateChooseItemQuery(''));
            setChooseItemKey((prev) => prev + 1);
        }
        //Else - THROW ERROR, RESET THE SCREEN AND LET THE USER TRY AGAIN
    };

    const getChoosePizzaHeader = (chosenCategoryName: string) => {
        console.log(chosenCategoryName, 'This is the chosen category name');

        switch (chosenCategoryName) {
            case `PAN פיצה`:
                return chooseItemTranslation('choose_pan_size');
            case 'פיצות מיוחדות':
                return chooseItemTranslation('choose_special_pizza');
            case 'פיצות אהובות':
                return chooseItemTranslation('choose_loved_pizza');
            case 'פיצות דקות':
                return chooseItemTranslation('choose_thin_size');
            default:
                return 'באיזה פיצה תרצו לבחור?';
        }
    };

    const getChooseItemHeader = () => {
        if (!chosenCategory || !chosenCategory.name) {
            return chooseItemTranslation('default_header');
        }
        switch (chosenCategory?.name) {
            case 'פיצות':
                return getChoosePizzaHeader(
                    (chosenSubCategory && chosenSubCategory.name) || 'פיצות',
                );
            case 'מנות נלוות':
                return chooseItemTranslation('choose_side_item');
            case 'סלטים':
                return chooseItemTranslation('choose_salad');
            case 'פסטות / קישים':
                return chooseItemTranslation('choose_pasta_item');
            case 'קינוחים':
                return chooseItemTranslation('choose_dessert_item');
            case 'שתייה':
                return chooseItemTranslation('choose_drink_item');
            case 'רטבים':
                return chooseItemTranslation('choose_sauce_item');
            default:
                return 'באיזה מוצר תרצו לבחור?';
        }
    };

    useEffect(() => {
        onRowFocus();
        focusSelf();
    }, []);

    // Debounce timeout
    const [debounceTimeout, setDebounceTimeout] = useState<NodeJS.Timeout | null>(null);

    useEffect(() => {
        if (voiceRecognitionText.length > 0) {
            // Clear any existing timeout
            if (debounceTimeout) {
                clearTimeout(debounceTimeout);
            }

            // Set a new timeout to process the voice input after a delay
            const timeout = setTimeout(() => {
                console.log('Processing voice input');
                dispatch(UpdateChooseItemQuery(voiceRecognitionText));
                if (chosenSubCategory) {
                    fetchMelingoData(`${chosenSubCategory.name} ${voiceRecognitionText}`);
                } else {
                    fetchMelingoData(voiceRecognitionText);
                }
                console.log('Sending to Melingo ', voiceRecognitionText);
                setVoiceRecognitionText(''); // Reset the text after processing
            }, 1000); // Adjust the delay as needed (1000 ms = 1 second)

            setDebounceTimeout(timeout);
        }

        // Cleanup function to clear the timeout if the component unmounts
        return () => {
            if (debounceTimeout) {
                clearTimeout(debounceTimeout);
            }
        };
    }, [voiceRecognitionText]);
    // useEffect(() => {
    //     if (voiceRecognitionText.length < 1) {
    //         return;
    //     }
    //     if (chosenSubCategory) {
    //         fetchMelingoData(`${chosenSubCategory.name} ${voiceRecognitionText}`);
    //     } else {
    //         fetchMelingoData(voiceRecognitionText);
    //     }
    //     dispatch(UpdateChooseItemQuery(voiceRecognitionText));
    // }, [voiceRecognitionText]);

    useEffect(() => {
        if (melingoData && melingoData.data) {
            setVoiceRecognitionText('');
            handleReceivedItemDataFromMelingo();
        }
    }, [melingoData]);

    return (
        <FocusContext.Provider value={focusKey}>
            <div
                className={`flex flex-col animate-slowFadeIn items-start justify-center w-full pr-[4vw] my-[2vw] ${isDisabled && `pointer-events-none`}`}
            >
                <div className="flex relative mb-[2vw]">
                    <span className="text-[1.8vw]">{getChooseItemHeader()}</span>
                    <PersonalAssistantLogo />
                </div>
                <div ref={ref} className="flex">
                    <div className="flex items-start mt-[3vw] ml-[2vw]">
                        {isCurrentStep && (
                            <StepBackButton
                                focusKey="SBB-CHOOSE-ITEM"
                                handleOnClick={handleStepBackFunction}
                                className="ml-[1.5vw]"
                            />
                        )}
                        <SpeechToTextButton
                            focusKey="STT-CHOOSE-ITEM"
                            isListening={isListening}
                            startClickFunction={startListening}
                            stopClickFunction={stopListening}
                        />
                    </div>
                    <div className="flex flex-wrap items-center justify-start w-[70vw] px-[0.5vw]">
                        {chosenCategoryItems.map((item, index) => {
                            const shouldBeSmaller =
                                chosenCategoryItems.length > 9 ||
                                chosenCategory?.name.includes('פיצות')
                                    ? true
                                    : false;
                            const shouldBeBigger = chosenCategory?.name.includes('רטבים')
                                ? true
                                : false;
                            return (
                                <ItemCard
                                    itemData={item}
                                    setCurrentFocusIndex={setCurrentFocusIndex}
                                    componentIndex={index}
                                    key={`item-card-${index}`}
                                    shouldBeSmaller={shouldBeSmaller}
                                    shouldBeBigger={shouldBeBigger}
                                    categoryName={chosenCategory?.name || ''}
                                />
                            );
                        })}
                    </div>
                </div>
            </div>
        </FocusContext.Provider>
    );
};

export default ChooseItem;
