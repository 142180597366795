import React from 'react';
import createTranslateFunction from '../../i18n/createTranslateFunction';
import QRCode from 'react-qr-code';
import { useSelector } from '../../redux/store';
import { Loading } from '../../components/Loading';

type PickACardStageTwoProps = {
    isQrGenerating: boolean;
};
const PickACardStageTwo = ({ isQrGenerating }: PickACardStageTwoProps) => {
    const GeneralTranslationFunction = createTranslateFunction('choose_payment.pick_a_card');
    const paymentLink = useSelector((state) => state.deliveryFormData.deliveryForm.payment_link);

    return (
        <div className="flex flex-col justify-center items-center">
            <span className="text-[2vw] text-white mb-[2vw] text-center max-w-[26vw]">
                {GeneralTranslationFunction('scan_to_pay')}
            </span>
            {isQrGenerating ? (
                <div className="w-[14vw] h-[14vw]">
                    <Loading className="!bg-transparent" />
                </div>
            ) : (
                paymentLink && (
                    <QRCode
                        className="w-[14vw] h-[14vw] p-[0.3vw] bg-white border-white"
                        value={`${paymentLink}`}
                    ></QRCode>
                )
            )}
        </div>
    );
};

export default PickACardStageTwo;
