const heIL = {
    buttons: {
        edit: 'ערוך',
        remove: 'הסר',
        delete: 'מחק',
        save: 'שמור',
        back: 'חזור',
        home_button: 'בית',
        save_button: 'שמור',
        add_to_cart: 'הוספה לסל',
        cart: 'הזמנה',
        sign_out: 'התנתק',
        confirm: 'אישור',
        decline: 'ביטול',
        okay: 'אוקיי',
        last_order: 'ההזמנה האחרונה',
        back_to_home: 'חזרה לעמוד הבית',
        order_details: 'פרטי ההזמנה',
    },
    confirm_modal: {
        delete_item_title: 'האם אתה בטוח שאתה רוצה למחוק את פריט זה?',
    },
    favorite_items_screen: {
        confirm_delete_modal_text: 'האם אתה בטוח שאתה רוצה למחוק את הפריט הזה מרשימת המועדפים?',
        order_button: 'הזמן',
        remove_button: 'הסר',
        no_favorite_items: {
            greetings: 'היי {{name}}.',
            favorite_items_are_empty: 'עדיין לא בחרת בפריט מועדף.',
            add_items_to_your_favorites: `בשביל להוסיף מוצרים, לחץ על אייקון הלב`,
            on_favorite_item_page: 'בדף המוצר שאהבת',
            back_to_home_button: 'לעמוד הבית',
        },
    },
    auth_screen: {},
    branches_screen: {
        header: 'הסניפים שלנו',
        search_input_placeholder: 'חפש את הסניף שלך',
    },
    chosen_branch_screen: {
        header: 'סניף {{name}}',
        opening_hours: 'שעות פתיחה:',
        day: 'יום {{day}}',
        phone_number: '1-700-50-60-70',
        order_from_branch_button: 'להזמנה מהסניף',
    },
    checkout_screen: {
        cart_popup: {
            title: `הזמנה`,
            items: '{{itemCount}} מוצרים',
            total: `סך הכל לתשלום`,
            no_delivery_cost: `עדיין אין מחיר למשלוח`,
        },
        delivery_form: {
            buttons: {
                pickup: `איסוף מהסניף`,
                delivery: `משלוח`,
                pay_with: `שלם עם כרטיס`,
                pay_now: `לתשלום`,
                switch_card: `החלף כרטיס`,
            },
            sub_headers: {
                choose_option: 'בחר אפשרות',
                estimated_time: 'זמן הכנה והגעה משוערים',
                minutes: 'דקות',
                notes: 'הערות',
                total_cost: 'סך הכל',
                delivery_cost: 'עלות משלוח:',
            },
            modals: {
                pickup_time: {
                    title: `שעת איסוף ההזמנה`,
                    sub_title: `בחרו את זמן האיסוף`,
                    first_time: `{{now time  + 30 min}}`,
                },
                delivery_time: {
                    title: `שעת הגעת המשלוח`,
                    sub_title: `בחרו את זמן המשלוח`,
                    first_time: `עכשיו`,
                    modal_title_delivery: `בחר שעה להגעת המשלוח`,
                    modal_title_pickup: `בחר שעה לאיסוף ההזמנה`,
                },
                delivery_address: {
                    title: 'כתובת למשלוח',
                    sub_title: `בחרו כתובת למשלוח`,
                    modal_title: 'בחר כתובת למשלוח',
                    delivery_notes: 'הערות',
                    no_addresses_title: 'אנא שייך כתובת לחשבונך',
                },
                delivery_branch: {
                    title: 'סניף משלוח',
                    sub_title: `ההזמנה תצא מסניף פיצה האט: `,
                },
                pickup_branch: {
                    title: 'בחר סניף לאיסוף',
                    sub_title: `ההזמנה תצא מסניף פיצה האט:`,
                },
                note: {
                    title: `הערות`,
                    placeholder: 'הערות להזמנה',
                    sub_title: `הוסיפו הערות משלוח`,
                },
                cvv: {
                    title: 'הזן את קוד הCVV שלך',
                    placeholder: 'קוד CVV',
                },
                pick_a_card: {
                    title: 'בחר את כרטיס האשראי שלך',
                    sub_title: `כרטיס האשראי הנבחר הוא:`,
                },
            },
        },
        alerts: {
            general_problem: 'קרתה בעיה, נא נסה שוב',
            order_submission_error: 'ההזמנה נכשלה, נא נסה שוב מאוחר יותר',
            empty_cart: 'עדיין אין לך מוצרים בעגלה, הוסף מוצר ונסה שוב',
            empty_address: 'עדיין לא נבחרה כתובת למשלוח, הוסף כתובת ונסה שוב',
            empty_branch: 'עדיין לא נבחר סניף, הוסף סניף ונסה שוב',
            empty_date: 'הזמן הרצוי להזמנה עדיין לא נבחר!',
            invalid_date: 'הזמן הרצוי להזמנה אינו תקין!',
        },
    },
    edit_food_screen: {
        personalize: {
            title: 'התאמה אישית',
            sub_title: `ניתן לבחור מקסימום 3 תוספות`,
            condition: 'לא ניתן להוסיף יותר מ {{count}} תוספות',
            sauces_title: `רטבים`,
            max_toppings_alert_title: 'מספר התוספות גבוה מדי',
            not_enough_toppings_alert_title: 'לא נבחרו מספיק תוספות',
            general_alert_title: 'הפעולה הזו לא אפשרית, נסה שוב',
            extras_selected: 'תוספות נבחרו',
            obligatory: 'נדרש',
            extras_not_selected: 'לא נבחרו תוספות',
        },
        sauces: {
            title: 'בחירת רטבים',
            sub_title: 'הרוטב הראשון ללא תשלום, כל רוטב נוסף בתוספת 1.5₪',
        },
    },
    home_screen: {
        title: 'שלום {{name}}, ',
        sub_title: `שמחים שנכנסת לחנות שלנו בטלוויזיה. באפשרותך להזמין מתוך מגוון רב של פיצות ומנות נלוות לבחירתך ולקבלן עד אליך לסלון.`,
        sub_title_withcart_item: `מה עוד נרצה להזמין?`,
        menu_row_title: '',
        past_orders_row_title: 'הזמנות קודמות',
        pick_delivery_type_modal: {
            title: 'איך נרצה את ההזמנה?',
            delivery: 'משלוח',
            pickup: 'איסוף עצמי',
        },
        past_orders: {
            show_details_popup: 'הראה פרטים',
            add_to_cart_button: 'הוספה לעגלה',
            remove_from_cart_button: 'הסר מהעגלה',
            time_of_order: 'זמן הזמנה',
            price_of_order: 'מחיר ההזמנה',
            items: 'פריטים',
            final_price: 'מחיר סופי',
            move_to_payment: 'לתשלום',
            payment_disclaimer: '*המחירים יחושבו מחדש בעת התשלום',
        },
    },
    menu_screen: {},
    profile_screen: {},
    search_screen: {
        header: 'עמוד החיפוש',
        input_placeholder: 'כאן כותבים מה אנחנו רוצים',
    },
    personal_assistant_screen: {
        choose_category: {
            header: 'היי, מה תרצו להזמין בבקשה?',
            already_ordered_header: 'תרצו להזמין משהו נוסף?',
        },
        choose_pizza_sub_category: {
            header: 'איזו פיצה תרצו לבחור?',
        },
        choose_sub_category: {
            choose_drink_size: 'באיזה גודל שתייה תבחרו?',
        },
        choose_item: {
            default_header: 'איזה מוצר תרצו להזמין?',
            choose_pan_size: 'איזה גודל של PAN פיצה תרצו?',
            choose_thin_size: 'איזה גודל של פיצה דקה תרצו?',
            choose_special_pizza: 'איזו פיצה מיוחדת תרצו?',
            choose_loved_pizza: 'איזו פיצה אהובה תרצו?',
            choose_salad: 'איזה סלט תרצו?',
            choose_side_item: 'איזו מנה נלוות תרצו להזמין?',
            choose_pasta_item: 'איזה פסטה או קיש תרצו להזמין?',
            choose_dessert_item: 'באיזה קינוח תבחרו?',
            choose_drink_item: 'באיזה שתייה תבחרו?',
            choose_sauce_item: 'איזה רוטב תרצו להוסיף?',
        },
        choose_extras: {
            pizza_header: 'באילו תוספות תרצו לבחור? (יש לבחור מקסימום 3 תוספות)',
            salad_header: 'באילו תוספות תרצו לבחור? (יש לבחור מינימום 3 תוספות, ומקסימום 8)',
            no_extras_button: 'ללא תוספות',
            finished_choosing_button: 'סיימתי לבחור!',
        },
        choose_to_keep_item: {
            header: 'אוקיי {{name}}, האם תרצו להוסיף את המוצר להזמנה?',
            add_to_cart_button: 'הוספה לסל',
        },
    },
    settings_screen: {
        main_page_button: 'לעמוד הראשי',
        disclaimer: `באפשרותכם לשנות את פרטי החשבון האישי שלכם והרישום אליו`,
        instruction: `באמצעות סריקת קוד QR זה`,
    },
    welcome: {
        title: 'ברוכים הבאים לפיצה האט :)',
        description: 'בחנות זו תוכלו ליהנות מחווית הזמנת פיצה היישר מהטלוויזיה עד אליכם לסלון',
        lets_start_button: 'אז יאללה, בואו נתחיל!',
    },
    register: {
        write_a_code: `רשום את הקוד`,
        write_a_code_description: `שלום אורח, נא הקש את הקוד שקיבלת בטלפון לאחר ההרשמה`,
        step_one: {
            title: `סרוק קוד QR`,
            description:
                'נא פתח את המצלמה במכשיר הנייד שלך, וכוון אותו אל עבר קוד הQR שבמסך בשביל להרשם. אם כבר נרשמת,',
            next_step_button: 'גש לשלב הבא',
        },
        step_two: {
            title: 'הרשמה',
            description:
                'סרקת את הקוד בהצלחה. עכשיו הרשם באפליקציה ותוכל ליהנות מהפיצה הטובה בעולם!',
        },
        step_three: {
            title: 'ברכות',
            description: 'גיתם, זיהינו את ההרשמה שלך ומיד נעביר אותך למסך הבית!',
        },
    },
    login: {
        connect_tv: {
            back_button: 'חזור',
            title: 'להתחברות דרך מכשיר הטלוויזיה',
            description: 'יש להקליד את מספר הטלפון הנייד לקבלת קוד אימות',
            input_placeholder: 'מספר טלפון נייד',
            send_a_code_button: 'לשליחת קוד אימות',
            or: 'או',
        },
        connect_phone: {
            title: 'להתחברות באמצעות המכשיר הנייד',
            description: 'יש לסרוק את קוד הQR באמצעות המצלמה של המכשיר הנייד שברשותך',
            insert_verification_code_button: 'להזנת קוד אימות',
        },
        alert_modal: {
            placeholder: 'קרתה בעיה, נסה שוב מאוחר יותר',
            invalid_phone_number: 'מספר הפלאפון שהוזן אינו תקין, נסה שוב.',
        },
    },
    login_verify: {
        header: 'הזנת הקוד',
        description: 'יש להקליד את הקוד אותו קיבלתם במכשיר הנייד',
        accept_button: 'אישור',
        havent_received_code: 'לא קיבלתי קוד אימות',
        sms_expired_modal_title: 'הקוד פג תוקף, בואו ננסה שוב',
        user_didnt_finish_boarding: 'למספר הטלפון הזה עדיין אין משתמש, אתה מועבר לדף יצירת משתמש',
    },
    choose_payment: {
        pick_a_card: {
            pick_a_card_modal_title: 'בחר את כרטיס האשראי הרצוי',
            back_button: 'חזור',
            title: `שלם עם כרטיס אשראי`,
            description: 'נא בחר את כרטיס האשראי איתו אתה רוצה לבצע את ההזמנה, וסרוק את קוד הQR',
            chosen_card: 'בחרת בכרטיס',
            input_placeholder: '3 ספרות על גב הכרטיס',
            pay_now_button: 'שלם עכשיו!',
            or: 'או',
            scan_to_pay: 'סרוק בשביל לשלם עם כרטיס האשראי שלך',
        },
        qr_code: {
            title: 'סרוק בשביל לשלם באמצעות',
        },
    },
    track_order: {
        minutes: 'דקות',
        statuses: {
            order_status: 'סטטוס הזמנה',
            pending: 'מישהו ראה את ההזמנה שלך',
            in_progress: 'ההזמנה שלך נמצאת בהכנה',
            on_delivery: 'ההזמנה שלך בדרך אליך',
            done: 'ההזמנה הגיעה לביתך :-)',
        },
        did_you_get_the_order_popup: {
            title: 'האם קיבלת את ההזמנה?',
            confirmation_button: 'כן, קיבלתי אותה',
            problem_text: 'יש בעיה עם ההזמנה?',
            call_us: 'התקשרו אלינו, 1-700-50-60-70',
        },
    },
};

export default heIL;
