import '@mantine/core/styles.css';

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import './assets/fonts/font.scss';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './redux/store';
// import { FpsView } from 'react-fps';

//import './lib/webOSTVjs-1.2.4/webOSTV.js';
//import './lib/webOSTVjs-1.2.4/webOSTV-dev.js';
import DataFetcher from './DataFetcher';
import { createTheme, MantineProvider } from '@mantine/core';
import moment from 'moment';
import 'moment/dist/locale/he';
moment.locale('he');

const theme = createTheme({});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <Provider store={store}>
        {/* <FpsView /> */}
        <BrowserRouter>
            <DataFetcher>
                <MantineProvider theme={theme}>
                    <App />
                </MantineProvider>
            </DataFetcher>
        </BrowserRouter>
    </Provider>,
);
